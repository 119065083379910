import store from "@/store"
import { PERFIS_PERMISSAO } from "../mixins/data/perfis"

const perfis = Object.keys(PERFIS_PERMISSAO)

export default {
    auth(to, from, next) {
        const modulos = store.getters['auth/controleacesso'];

        if (
            !store.getters['auth/authenticated']
            || !to.meta.modulos
            || ( to.meta.modulos && !modulos.some(modulo => to.meta.modulos.includes(modulo.modulo)))
            || ( to.meta.perfil && !perfis.some(perfil => perfil === to.meta.perfil))
        ) {
            return next({ name: 'Login' })
        }
        else next()
    },

    isAuth(to, from, next) {
        const user = store.getters["auth/user"];
        if (store.getters['auth/authenticated'] && !user?.senhaPadrao) {
            return next({name: 'home'})
        }
        else next()
    },

    home(to, from, next) {
        if (!store.getters['auth/authenticated']) {
            return next({ name: 'Login' })
        }
        else next()
    },
}