
export default {
  namespaced: true,
  state: {
    prontuarioMedico: null,
  },
  getters: {
    prontuaioMedico(state) {
      return state.prontuarioMedico
    },
  },
  mutations: {
    SET_PRONTUARIO_MEDICO(state, prontuario) {
      state.prontuarioMedico = prontuario
    },
  },
  actions: {
    Action_Set_Prontuario_Medico({ commit }, prontuarioId) {
      commit('SET_PRONTUARIO_MEDICO', prontuarioId)
    },
  }
}
