<template>
  <v-navigation-drawer
    v-model="$store.state.drawer"
    class="nav"
    app
    dark
    fixed
    color="black"
    width="290"
  >
    <Logo style="cursor: pointer" @click.native="push()" />

    <v-list shaped nav>
      <NavItem
        color="primary"
        v-for="item in itensPermitidos"
        :key="item.title"
        :to="item.to"
        :title="item.title"
        :submenu="item.sub"
        :icon="item.icon"
      />
    </v-list>

    <template v-slot:append>
      <div class="pa-2 mb-3">
        <v-btn @click="logOut()" block> Sair </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import NavItem from '@/components/atoms/items/NavItem.vue'
import Logo from '@/components/atoms/Logo.vue'

import { mapActions } from 'vuex'

export default {
  components: {
    NavItem,
    Logo
  },
  data() {
    return {
      items: [
        {
          title: 'Acesso',
          acesso: 'ACESSO',
          icon: 'mdi-account-lock-open',
          sub: [{ title: 'Usuários', to: '/usuarios' }]
        },
        {
          title: 'Institucional',
          acesso: 'INSTITUCIONAL',
          icon: 'mdi-domain',
          sub: [
            { title: 'Informações CAAMA', to: '/institucional' },
            { title: 'Filiais CAAMA', to: '/filiais' }
          ]
        },
        {
          title: 'Auxílios',
          acesso: 'AUXILIO',
          icon: 'mdi-handshake',
          sub: [
            { title: 'Auxílio Maternidade', to: '/auxilio-maternidade' },
            { title: 'Auxílio Funeral', to: '/auxilio-funeral' }
          ]
        },
        {
          title: 'Saúde e Bem-Estar',
          acesso: 'AGENDA',
          icon: 'mdi-calendar',
          sub: [
            { title: 'Agenda Médica', to: '/agenda' },
            { title: 'Listar Agenda', to: '/lista-agenda' },
            { title: 'Agendamentos', to: '/tabela-agenda' }
          ]
        },
        {
          title: 'Saúde e Bem-Estar',
          acesso: 'AGENDA ATENDENTE',
          icon: 'mdi-calendar',
          sub: [
            { title: 'Agenda Médica', to: '/agenda' },
            { title: 'Listar Agenda', to: '/lista-agenda' },
            { title: 'Agendamentos', to: '/tabela-agenda' }
          ]
        },
        {
          title: 'Perfil',
          acesso: 'PERFIL COLABORADOR',
          icon: 'mdi-account-multiple',
          sub: [{ title: 'Dados', to: '/perfil' }]
        },
        {
          title: 'Saúde e Bem-Estar',
          acesso: 'AGENDA COLABORADOR',
          icon: 'mdi-calendar',
          sub: [{ title: 'Prontuário Médico ', to: '/prontuario-medico' }]
        },
        {
          title: 'Colaborador',
          acesso: 'COLABORADOR',
          icon: 'mdi-account-circle',
          sub: [
            { title: 'Disponibilidade', to: '/disponibilidade-colaborador' },
            { title: 'Listar Colaboradores', to: '/lista-colaboradores' },
            { title: 'Especialidades', to: '/especialidades' }
          ]
        },
        {
          title: 'Parceiro',
          acesso: 'PARCEIRO',
          icon: 'mdi-card-account-details',
          sub: [{ title: 'Listar Parceiros', to: '/lista-parceiros' }]
        },
        {
          title: 'Associado',
          acesso: 'ASSOCIADO',
          icon: 'mdi-account',
          sub: [{ title: 'Listar Associados', to: '/lista-associados' }]
        },
        {
          title: 'Comunicação',
          acesso: 'PORTAL',
          icon: 'mdi-bullhorn',
          sub: [{ title: 'Gerenciar Portal', to: '/gerenciar-portal' }]
        },
        {
          title: 'Utilitarios',
          acesso: 'UTILITARIOS',
          icon: 'mdi-view-dashboard',
          sub: [{ title: 'Integração', to: '/integracao' }]
        },
        {
          title: 'Usuario',
          acesso: 'USUARIO',
          icon: 'mdi-account-multiple',
          sub: [{ title: 'Redefinir Senha', to: '/redefinir-senha' }]
        }
      ],
      itensPermitidos: []
    }
  },

  methods: {
    ...mapActions({
      sair: 'auth/logOut'
    }),

    gerenciarAsseco() {
      const permitidos = this.$store.getters['auth/controleacesso']
      this.itensPermitidos = this.items.filter((item) => {
        for (let index = 0; index < permitidos.length; index++) {
          if (item.acesso === permitidos[index].modulo) return true
        }
      })
    },
    push() {
      this.$router.push('/')
    },
    callDialog() {
      this.dialog = !this.dialog
      return this.dialog
    },
    logOut() {
      this.$store.dispatch('auth/logOut').then((saiu) => {
        if (saiu) {
          this.$router.replace({ name: 'Login' })
        }
      })
    }
  },
  created() {
    this.gerenciarAsseco()
  }
}
</script>

<style></style>
