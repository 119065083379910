import axios from 'axios'

const URL_COLABORADOR = '/api-prestador/colaborador'
const URL_EMPRESA_FILIAL = '/api-prestador/empresaColaborador'
const URL_AGENDA = '/api-agenda'

const atribuirParametros = (filtro) => {
  let parametros = []
  for (const [key, value] of Object.entries(filtro)) {
    if (value || value === 0) parametros.push(`${key}=${value}`)
  }

  if (parametros.length <= 0) return ''
  return parametros.length === 1 ? `?${parametros}` : `?${parametros.join('&')}`
}

export default {
  colaboradores(paginacao, filtro) {
    const parametros = atribuirParametros({ ...filtro, ...paginacao })
    const url = `${URL_COLABORADOR}/listar${parametros}`
    return axios.get(url)
  },

  colaboradorPorNome(empresaId, nome) {
    const url = `${URL_COLABORADOR}/empresa/${empresaId}/colaborador/${nome}/naoVinculado`
    return axios.get(url)
  },

  colaboradorPorId(colobaradorId) {
    const url = `${URL_COLABORADOR}/${colobaradorId}`
    return axios.get(url)
  },

  colaboradoresNaoVinculadosAFilial(colobaradorId, paginacao) {
    const parametros = atribuirParametros(paginacao)
    const url = `${URL_COLABORADOR}/empresa/${colobaradorId}/naoVinculado${parametros}`
    return axios.get(url)
  },

  colaboradoresVinculadosAFilial(colobaradorId, paginacao) {
    const parametros = atribuirParametros(paginacao)
    const url = `${URL_COLABORADOR}/empresa/${colobaradorId}${parametros}`
    return axios.get(url)
  },

  vincularColaboradorAFilial(body) {
    const url = `${URL_EMPRESA_FILIAL}/vincularColaborador/`
    return axios.post(url, body)
  },

  detalharColaboradorPorId(colobaradorId) {
    const url = `${URL_COLABORADOR}/${colobaradorId}/detalhar`
    return axios.get(url)
  },

  detalharColaboradorPorCpf(colobaradorCpf) {
    const url = `${URL_COLABORADOR}/cpf/${colobaradorCpf}`
    return axios.get(url)
  },

  colaboradorPorFilial(filialId) {
    const url = `${URL_AGENDA}/colaborador/empresa/${filialId}`
    return axios.get(url)
  },

  cadastrarColaborador(body) {
    const url = `${URL_COLABORADOR}`
    return axios.post(url, body)
  },

  atualizarColaborador(body, colaboradord) {
    const url = `${URL_COLABORADOR}/${colaboradord}/atualizar`
    return axios.put(url, body)
  },

  atualizarStatus(colaboradorId, empresaId, body) {
    const url = `${URL_COLABORADOR}/AtualizarStatus/colaborador/${colaboradorId}/empresa/${empresaId}`
    return axios.put(url, body)
  },

  especializacoesPorColaborador(colaboradorId, empresaId, body) {
    const url = `${URL_COLABORADOR}/${colaboradorId}/Especializcoao/vinculado`
    return axios.get(url)
  },

  especializacoesNaoVinculadasAoColaborador(colaboradorId) {
    const url = `${URL_COLABORADOR}/${colaboradorId}/Especializacao/naoVinculado`
    return axios.get(url)
  },

  especializacoesNaoVinculadasAoColaboradorPorProfissao(body) {
    const parametros = atribuirParametros({ ...body })
    const url = `${URL_COLABORADOR}/Especializacao/naoVinculado${parametros}`
    return axios.get(url)
  },

  vincularEspecializacaoAoColaborador(body) {
    const url = `${URL_COLABORADOR}/especializacao/vincular`
    return axios.post(url, body)
  },

  desativarEspecializacaoAoColaborador(colaboradorId, especializacaoId) {
    const url = `${URL_COLABORADOR}/${colaboradorId}/especializacao/${especializacaoId}/desativar`
    return axios.put(url)
  },

  ativarEspecializacaoAoColaborador(colaboradorId, especializacaoId) {
    const url = `${URL_COLABORADOR}/${colaboradorId}/especializacao/${especializacaoId}/ativar`
    return axios.put(url)
  },

  atualizarStatusColaborador(vinculoId, body) {
    const url = `${URL_COLABORADOR}/vinculo/${vinculoId}/atualizar-status`
    return axios.put(url, body)
  },

  especialidades(paginacao) {
    const parametros = atribuirParametros({ ...paginacao })
    const url = `${URL_AGENDA}/especializacao${parametros}`
    return axios.get(url)
  },

  especialidadesPorDescricao(filtro) {
    const parametros = atribuirParametros(filtro)
    const url = `${URL_AGENDA}/especializacao/descricao${parametros}`
    return axios.get(url)
  },

  especialiadePorId(especializacaoId) {
    const url = `${URL_AGENDA}/especializacao/${especializacaoId}`
    return axios.get(url)
  },

  cadastrarEspecialidade(body) {
    const url = `${URL_AGENDA}/especializacao`
    return axios.post(url, body)
  },

  alterarEspecialidade(especializacaoId, body) {
    const url = `${URL_AGENDA}/especializacao/${especializacaoId}`
    return axios.put(url, body)
  },

  desvincularProfissaoAEspecialidade(especialidadeId, profissaoId) {
    const url = `${URL_AGENDA}/especializacao/${especialidadeId}/profossao/${profissaoId}/desvincular`
    return axios.put(url)
  },

  profissoesNaoVinculadasAEspecializacao(especializacaoId) {
    const url = `${URL_AGENDA}/especializacao/${especializacaoId}/profissao/nao-vinculado`
    return axios.get(url)
  },
}
