<template>
  <v-btn
    v-if="!erroValidacao"
    class="radius"
    :style="estilo"
    :type="tipoBotao"
    :class="[
      { 'blue white--text': primary },
      { 'red darken-1 white--text': secondary },
      { 'yellow darken-3 white--text': warning },
      { 'green  white--text': success },
      { 'overflow-hidden': overflowHidden }
    ]"
    large
    :loading="loading"
    :disabled="desabilitar || loading"
    @click.native="$emit('clickBotao', evento)"
  >
    <v-icon
      v-if="icone"
      class="icone"
      :color="corIcone"
      :class="[
        { 'animacacao-spin': animacao === 'spin' },
        { 'animacacao-download': animacao === 'download' }
      ]"
    >
      mdi-{{ icone }}
    </v-icon>

    {{ text }}

    <v-icon
      v-if="iconeFinal"
      class="icone-final"
      :class="[
        { 'animacacao-spin': animacaoIconeFinal === 'spin' },
        { 'animacacao-download': animacaoIconeFinal === 'download' }
      ]"
    >
      mdi-{{ iconeFinal }}
    </v-icon>
  </v-btn>

  <div v-else>
    <v-btn
      class="radius"
      :style="estilo"
      :type="tipoBotao"
      :class="[
        { 'blue white--text': primary },
        { 'red darken-1 white--text': secondary },
        { 'yellow darken-3 white--text': warning },
        { 'green  white--text': success },
        { 'overflow-hidden': overflowHidden }
      ]"
      large
      :loading="loading"
      :disabled="desabilitar || loading"
      @click.native="$emit('clickBotao', evento)"
    >
      <v-icon
        v-if="icone"
        class="icone"
        :color="corIcone"
        :class="[
          { 'animacacao-spin': animacao === 'spin' },
          { 'animacacao-download': animacao === 'download' }
        ]"
      >
        mdi-{{ icone }}
      </v-icon>

      {{ text }}

      <v-icon
        v-if="iconeFinal"
        class="icone-final"
        :class="[
          { 'animacacao-spin': animacaoIconeFinal === 'spin' },
          { 'animacacao-download': animacaoIconeFinal === 'download' }
        ]"
      >
        mdi-{{ iconeFinal }}
      </v-icon>
    </v-btn>

    <div class="erro-validacao">{{ erroValidacao  }}</div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: [
    'erroValidacao',
    'keyProp',
    'evento',
    'tipoBotao',
    'estilo',
    'loading',
    'outlined',
    'text',
    'primary',
    'secondary',
    'warning',
    'success',
    'tooltip',
    'icone',
    'corIcone',
    'animacao',
    'desabilitar',
    'overflowHidden',
    'animacaoIconeFinal',
    'iconeFinal'
  ]
}
</script>

<style scoped>
.radius {
  border-radius: 10px;
}

.icone {
  margin-right: 0.5rem;
}

.icone-final {
  margin-left: 0.5rem;
}

.overflow-hidden {
  overflow: hidden;
}

.animacacao-spin {
  animation: spin 2s ease infinite;
}

.animacacao-download {
  animation: download 1.3s infinite;
}

.erro-validacao {
  color: #ff5252 !important;
  caret-color: #ff5252 !important;
  line-height: 12px;
  padding: 0.6rem 0 0 0.6rem;
  word-break: break-word;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

@keyframes download {
  0% {
    transform: translateY(-150%);
  }
  100% {
    transform: translateY(150%);
  }
}

@keyframes spin {
  0% {
    transform: rotateZ(0);
  }
  100% {
    transform: rotateZ(360deg);
  }
}
</style>
