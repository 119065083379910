<template>
  <v-alert border="top" colored-border elevation="2" :type="type">
    {{ message }}
  </v-alert>
</template>

<script>
import ButtonMain from "@/components/atoms/buttons/ButtonMain.vue";

export default {
  components: {
    ButtonMain,
  },
  props: {
    message: String,
    body: String,
    type: {
      default: "error",
    },
  },
};
</script>

<style></style>
