<template>
  <v-dialog
    :persistent="persistente"
    :value="show"
    :max-width="width"
    :scrollable="scrollable"
    @click:outside="!persistente && $emit('close')"
  >
    <v-card class="mx-auto">
      <v-toolbar
        :class="[
          { 'blue white--text': tag === 'add' },
          { 'green white--text': tag === 'activate' },
          { 'red darken-1 white--text': tag === 'delete' },
          { 'yellow darken-3 white--text': tag === 'edit' }
        ]"
        elevation="1"
        dense
      >
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          @click="$emit('close')"
          color="white"
          icon
          class="hidden-xs-only"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <div class="subtitle-1 mt-2">
          <slot name="body"></slot>
        </div>
      </v-card-text>
      <v-card-actions v-if="exibirAcoes">
        <v-spacer></v-spacer>
        <slot name="footer">
          <div class="footer-confirmar">
            <v-btn
              text
              color="red darken-1"
              :disabled="loading"
              @click="$emit('close')"
            >
              Cancelar
            </v-btn>

            <v-btn text color="blue"
              :disabled="desabilitarConfirmacao || loading"
              :loading="loading"
              @click="$emit('confirm')"
            >
              Confirmar
            </v-btn>
          </div>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: Boolean,
    desabilitarConfirmacao: Boolean,
    tag: String,
    title: String,
    primary: Boolean,
    secondary: Boolean,
    warning: Boolean,
    success: Boolean,
    scrollable: Boolean,
    persistente: {
      type: Boolean,
      default: false,
    },
    exibirAcoes: {
      type: Boolean,
      default: true,
    },
    width: {
      type: Number,
      default: 660
    },
    loading: {
      type: Boolean,
      default: false,
    },
  }
}
</script>

<style>
  .footer-confirmar {
    display: flex; justify-content: flex-end;
    width: 100%; padding: 0.5rem 0;
  }
</style>
