import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import prontuario from './modules/prontuario'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    headerTitle: 'App',
    drawer: null,
    loading: false,
  },
  mutations: {
    toggleHeader(state) {
      state.drawer = !state.drawer;
    },
    setHeadeTitle(state, payload) {
      state.headerTitle = payload;
    },
    SET_LOADING(state, payload) {
      state.loading = payload
    }
  },
  actions: {
    actionToggleHeader({ commit }) {
      commit('toggleHeader');
    },
    actionSetHeaderTitle({ commit }, title) {
      commit('setHeadeTitle', title);
    },
    loading({ commit }, payload) {
      commit('SET_LOADING', payload)
    }
  },
  getters: {
    getDrawerHeader: state => state.drawer,
    getTitleHeader: state => state.headerTitle,
  },
  modules: {
    auth,
    prontuario,
  }
})
