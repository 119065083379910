<template>
  <v-snackbar
    app
    v-model="snackbar.visible"
    :color="snackbar.color"
    :multi-line="true"
    :timeout="snackbar.timeout"
    height="90"
    top
  >
    <v-layout align-center>
      <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
      <v-layout column class="corpo-notificacao">
        <div class="texto-notificacao">
          <div><strong>{{ snackbar.title }}</strong> </div>
          <div>{{ snackbar.text }}</div>
        </div>

        <v-btn icon class="botao-acao ml-7" depressed
          @click="snackbar.visible = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-layout>

    </v-layout>
  </v-snackbar>
</template>

<script>
  export default {
    name: "vtoast",
    data() {
      return {
        snackbar: {
          color: null,
          icon: null,
          mode: null,
          position: "top",
          text: null,
          timeout: 7500,
          title: null,
          visible: false,
        },
        timeout: 7500,
      };
    },
    methods: {
      show(type) {
        if (!type) return;
        switch (type.status) {
          case "error":
            this.snackbar = {
              color: "error",
              icon: "mdi-alert",
              mode: "multi-line",
              position: "top",
              timeout: type.time || 15000,
              title: type.title ? type.title : '',
              text: type.text,
              visible: true,
            };
            break;
          case "info":
            this.snackbar = {
              color: "info",
              icon: "mdi-alert-circle",
              mode: "multi-line",
              position: "top",
              timeout: type.time || 10000,
              title: type.title ? type.title : '',
              text: type.text,
              visible: true,
            };
            break;
          case "success":
            this.snackbar = {
              color: "success",
              icon: "mdi-check-bold",
              mode: "multi-line",
              position: "top",
              timeout: type.time || 7000,
              title: type.title ? type.title : '',
              text: type.text,
              visible: true,
            };
            break;
          case "warning":
            this.snackbar = {
              color: "warning",
              icon: "mdi-alert-circle",
              mode: "multi-line",
              position: "top",
              timeout: type.time || 15000,
              title: type.title ? type.title : '',
              text: type.text,
              visible: true,
            };
            break;
        }
      },

      close() {
        this.snackbar.visible = false
      }
    },
  };
</script>

<style scoped>
  .corpo-notificacao {
    display: flex; flex-direction: row;
    justify-content: space-between;  align-items: center;
    width: 100%;
  }

  .texto-notificacao {
    display: flex; flex-direction: column;
  }
</style>
