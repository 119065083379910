<script>
import moment from 'moment'
import associadoService from '@/services/AssociadoService'
import colaboradorService from '@/services/ColaboradorService'
import { formatInTimeZone } from 'date-fns-tz'

export default {
  methods: {
    dateSubtract30Days() {
      return moment().subtract(30, 'days').format('YYYY-MM-DD')
    },

    addDays(days, date) {
      return moment(date).add(days, 'days').format('DD/MM/YYYY')
    },

    dateFormatISO(date) {
      return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD')
    },

    dataValidaBr(dataBr) {
      if (!dataBr || dataBr.length < 10) return false

      const dataISO = moment(this.dateFormatISO(dataBr))
      return dataISO.isValid()
    },

    dateFormatBR(date) {
      if (date === null || date === undefined) return null
      return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY')
    },

    getTime(date) {
      return moment(date, 'YYYY-MM-DDTHH:mm').format('HH:mm')
    },

    onlyNumbers(texto) {
      if (typeof texto !== 'string') return ''

      return texto.replace(/[^0-9]/g, '')
    },

    greaterThan(date, initialDate) {
      const atual = this.dateFormatISO(date)
      const inicial = this.dateFormatISO(initialDate)

      if (date && initialDate !== null) {
        return atual >= inicial ? true : false
      }
    },

    diferencaEntreDatas(dataInicial, dataFinal, medida) {
      if (!dataInicial || !dataFinal) return 0

      const inicial = moment(this.dateFormatISO(dataInicial))
      const final = moment(this.dateFormatISO(dataFinal))
      return final.diff(inicial, medida)
    },

    horaAtual(fusoHorario, formato) {
      return formatInTimeZone(Date.now(), fusoHorario, formato)
    },

    diferencaTempoAgoraAteDataEspecificada(dataAgendameto, unidadeDeMedida) {
      const formatoHoraEMinutosMoment = 'DD/MM/YYYY HH:mm'
      const formatoHoraEMinutosDateFns = 'dd/MM/yyyy HH:mm'
      const dataAgoraSaoPaulo = formatInTimeZone(
        Date.now(),
        'America/Sao_Paulo',
        formatoHoraEMinutosDateFns
      )
      return moment(dataAgendameto, formatoHoraEMinutosMoment).diff(
        moment(dataAgoraSaoPaulo, formatoHoraEMinutosMoment),
        unidadeDeMedida
      )
    },

    gerarHorarioCargaIntegracao(data) {
      return moment(data, 'DD-MM-YYYY_HH:mm:ss')
        .subtract(3, 'hours')
        .format('DD/MM/YYYY HH:mm')
    },

    setTelefone(ddd, telefone) {
      if (ddd && telefone) return `(${ddd}) ${telefone}`
      if (!ddd && telefone) return `${telefone}`
      return null
    },

    atribuirParametros(filtro) {
      let parametros = []
      for (const [key, value] of Object.entries(filtro)) {
        if (value || value === 0) parametros.push(`${key}=${value}`)
      }

      if (parametros.length <= 0) return ''
      return parametros.length === 1
        ? `?${parametros}`
        : `?${parametros.join('&')}`
    },

    verificarSeHaCampoObrigatorioNulo(campos, validadores) {
      let campoInvalido = false
      const camposIgnorar = ['ButtonMain', 'DividerMain']

      for (const value of Object.values(campos)) {
        const valorCampo = value.value
        const regras = value.rules
        const tipoCampo = value.field
        let erroCampo = false

        if (
          !camposIgnorar.some((campo) => campo === tipoCampo) &&
          regras &&
          regras.length
        ) {
          for (const regra of regras) {
            if (
              Object.keys(validadores).some(
                (key) => regra(valorCampo) === validadores[key]
              )
            ) {
              erroCampo = true
              break
            }
          }
        }

        if (erroCampo) {
          campoInvalido = true
          break
        }
      }

      return campoInvalido
    },

    getPacienteSlug() {
      if (!this.$route?.params?.slug) return null
      return JSON.parse(atob(this.$route.params.slug))
    },

    async getPacienteMedico() {
      const paciente = this.getPacienteSlug()

      if (!paciente) {
        this.$root.vtoast.show({
          status: 'error',
          text: 'Paciente não encontrado',
          title: 'Erro!'
        })
        return
      }

      return paciente.dependente
        ? await associadoService.dependentePorId(paciente.id)
        : await associadoService.associadoPorId(paciente.id)
    },

    async getColaboradorLogado() {
      const cpf = this.getCPFColaborador()
      if (!cpf) return

      return await colaboradorService.detalharColaboradorPorCpf(cpf)
    },

    getCPFColaborador() {
      const cpf = this.$store.getters['auth/cpf']

      if (!cpf) {
        this.$root.vtoast.show({
          status: 'error',
          title: 'Atenção!',
          text: 'CPF do colaborador não encontrado. Por favor, refaça seu login.',
          time: 25000
        })
        return null
      }

      return cpf
    }
  }
}
</script>
