<template>
  <v-app id="inspire">
    <v-main class="mt-1">
      <vloader ref="vloader" />
      <vtoast ref="vtoast" />
      <!-- <Dialog
        :show="dialog"
        @close="dialog = false"
        @confirm="resetPassword()"
        tag="edit"
        title="Resetar Senha"
      >
        <template v-slot:body>
          <div class="form-fields fields">
            <InputValidacaoSenha
              label="Nova Senha"
              type="password"
              :cols="12"
              :valueField="senha"
              :rules="senhaRules.rules"
              :validacaoPopup="senhaRules.validacaoPopup"
              :validadores="senhaRules.validadores"
              @update:value="senha = $event"
            />

            <Input
              type="password"
              :cols="12"
              label="Confirmar Nova Senha"
              :iconeSenha="'mdi-eye-outline'"
              :valueField="confirmarSenha"
              :rules="confirmarRules"
              @update:value="confirmarSenha = $event"
            />
          </div>
        </template>
      </Dialog> -->
      <NavBar v-if="$store.getters['auth/authenticated'] && !senhaPadrao" />
      <v-container fluid>
        <!-- <v-alert
          v-if="
            $store.getters['auth/authenticated'] &&
            senhaPadrao &&
            !reseted
          "
          dense
          type="warning"
        >
          <strong>Senha Padrão:</strong>
            Caro usuário, por motivos de segurança, atualize sua senha
            <v-btn elevation="1" @click="modalResetPassword()" small>
              clicando aqui.
            </v-btn>
        </v-alert> -->

        <Header
          :titulo="getDrawerHeader"
          v-if="$store.getters['auth/authenticated'] && !senhaPadrao"
        />
        <v-sheet color="white" min-height="500" elevation="0" rounded>
          <router-view></router-view>
        </v-sheet>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import NavBar from '@/components/molecules/NavBar.vue'
import Header from '@/components/molecules/Header.vue'
import vtoast from '@/components/atoms/vtoast.vue'
import vloader from '@/components/molecules/vloader.vue'
import AlertMain from '@/components/atoms/AlertMain.vue'
import Input from '@/components/atoms/fields/InputField.vue'
import Dialog from '@/components/molecules/dialogs/DialogConfirmation.vue'
import InputValidacaoSenha from './components/atoms/fields/InputValidacaoSenha.vue'
import api from '@/services/api'
import { validadoresSenha } from './mixins/data/validadoresSenha.js'
import { mapGetters } from 'vuex'

export default {
  components: {
    NavBar,
    Header,
    vtoast,
    vloader,
    AlertMain,
    Dialog,
    Input,
    InputValidacaoSenha
  },

  mixins: [validadoresSenha],

  data() {
    return {
      reseted: false,
      logged: true,
      drawer: null,
      dialog: false,
      senha: null,
      confirmarSenha: null,

      senhaRules: {},
      confirmarRules: []
    }
  },

  methods: {
    modalResetPassword() {
      this.dialog = true
    },
    resetPassword() {
      if(!this.senha) {
        this.$root.vtoast.show({
          status: "warning",
          title: "Atenção!",
          text: "Insira uma senha.",
          time: 2000,
        });

        return
      }

      api
        .putUsuarioSenha(this.user.usuario, this.senha)
        .then((_) => {
          this.reseted = true;
          this.$root.vtoast.show({
            status: "success",
            text: "Senha Atualizada.",
            title: "Sucesso!",
          });
        })
        .catch((err) => {
          this.$root.vtoast.show({
            status: "error",
            text: err.response?.data.mensagemUsuario,
            title: err.response?.data.titulo,
          });
        });

      this.dialog = false;
    },
  },

  mounted() {
    this.$root.vtoast = this.$refs.vtoast
    this.$root.vloader = this.$refs.vloader

    this.senhaRules = {
      field: 'InputValidacaoSenha',
      label: 'Senha',
      valueField: null,
      exibirValidacao: false,
      validacaoPopup: false,
      type: 'password',
      placeholder: 'Senha',
      cols: 12,
      rules: this.REGRAS_SENHA,
      validadores: this.CONDICOES_SENHA
    }

    this.confirmarRules = [
      (value) => value === this.senha || 'As senhas não são iguais'
    ]
  },

  computed: {
    ...mapGetters(['getDrawerHeader', { autenticado: 'auth/authenticated' }]),
    user() {
      const user = this.$store.getters["auth/user"];
      return user || null;
    },
    senhaPadrao() {
      const user = this.$store.getters["auth/user"];
      return user.senhaPadrao || false
    },
  },
};
</script>

<style>
#inspire {
  background-color: #eceff1;
}
</style>

<style scoped>
.nav {
  border-radius: 0 20px 17px 0;
}

.fields {
  display: flex;
  flex-wrap: wrap;
  align-content: stretch;
  align-items: flex-start;
  flex: 1;
}

.form-fields {
  width: 100% !important;
  padding: 0 !important;
  margin: 0 !important;
}
</style>
