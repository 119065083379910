<template>
  <v-list-item>
    <v-list-item-content>
      <v-list-item-title class="title pa-2">
        <img width="100%" :src="image" alt="Logo OAB" />
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>
<script>
import image from '@/assets/oab.png'

export default {
  data() {
    return {
      image: image
    }
  }
}
</script>
