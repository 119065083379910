<template>
  <v-col cols="12" :md="cols">
    <v-text-field
      outlined
      min="0"
      v-model="text"
      hide-details="auto"
      :key="keyProp"
      :type="tipoInput"
      :label="label"
      :placeholder="placeholder"
      :rules="rules"
      :disabled="disabled"
      :readonly="readonly"
      :append-icon="iconeVerSenha"
      @click:append="trocarTipoInput()"
    >
    </v-text-field>
  </v-col>
</template>

<script>
  import globalMethods from "@/mixins/globalMethods.vue";

  export default {
    inheritAttrs: false,
    name: 'InputField',
    props: [
      'keyProp',
      'label',
      'placeholder',
      'cols',
      'row',
      'name',
      'rules',
      'valueField',
      'type',
      'disabled',
      'readonly',
      'iconeSenha',
      'apenasNumero'
    ],

    data() {
      return {
        text: this.valueField || '',
        tipoInput: this.type,
        iconeVerSenha: this.iconeSenha,
      }
    },

    mixins: [globalMethods],

    methods: {
      trocarTipoInput() {
        if (this.tipoInput === 'password') {
          this.tipoInput = 'text'
          this.iconeVerSenha = 'mdi-eye-off-outline'
        } else {
          this.tipoInput = 'password'
          this.iconeVerSenha = this.iconeSenha
        }
      },
    },

    watch: {
      valueField: {
        immediate: true,
        handler(newText, oldText) {
          if ((newText === '' || newText === 0 || newText) && newText !== oldText) {
            this.text = this.apenasNumero
              ? this.onlyNumbers(this.newText)
              : newText
          }
        }
      },
      text: {
        immediate: true,
        handler() {
          if(this.apenasNumero) {
            this.text = this.onlyNumbers(this.text)
            this.$emit('update:value', this.text)
            return
          }

          this.$emit('update:value', this.text)
        }
      },
      clearInput() {
        this.text = ''
      }
    },
  }
</script>

<style scoped>
.label {
  font-weight: 600;
  color: rgb(98, 98, 98);
  margin-bottom: 5px;
}
</style>
