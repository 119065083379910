<template>
  <v-list-group :color="color" ripple >
    <template v-slot:activator>
      <v-list-item-icon>
        <v-icon>{{ icon }}</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ title }}</v-list-item-title>
      </v-list-item-content>
    </template>
    <v-list-item
      v-for="sub in submenu"
      :key="sub.title"
      :to="sub.to"
      link
    >
      <v-list-item-title class="pl-3">{{sub.title}} </v-list-item-title>
      <!-- <v-list-item-icon>
        <v-icon v-text="sub.icon"></v-icon>
      </v-list-item-icon> -->
    </v-list-item>
  </v-list-group>
</template>

<script>
export default {
  props: {
    title: {
      required: true,
      type: String
    },
    submenu: [],
    to: {
      type: String
    },
    icon: {
      type: String
    },
    color: {
      type: String,
      default: 'primary'
    }
  },
}
</script>

<style></style>
