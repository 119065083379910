<template>
  <v-col cols="12 coluna-input" :md="cols">
    <v-text-field
      outlined
      v-model="text"
      :label="label"
      :placeholder="placeholder"
      :rules="rules"
      :disabled="disabled"
      :readonly="readonly"
      :type="tipoInput"
      :append-icon="iconSenha"
      :hide-details="true"
      @click:append="trocarTipoInput()"
      @focus="exibirCardValidacao = true; verificarCondicao(text)"
      @keyup="verificarCondicao(text)"
    >
      <template
        v-slot:append-outer
        v-if="exibirCardValidacao"
      >
        <div
          :class="{
            'card-validacao-senha': true,
            'popup': validaoEmFormatoPopup
          }"
        >
          <div class="corpo-card">
            A senha deve conter:
            <ul class="itens mt-2">
              <li
                v-for="(validador, index) in validadoresSenha"
                :key="index"
              >
                <v-icon
                  small
                  class="mr-1"
                  :color="validador.invalido ? '#ef2727' : '#12960f'"
                >
                  mdi-{{validador.invalido ? 'close-circle' : 'checkbox-marked-circle'}}
                </v-icon
                >
                {{ validador.condicao }}
              </li>
            </ul>
          </div>

          <div class="seta-popup"></div>
        </div>
      </template>
    </v-text-field>
  </v-col>
</template>

<script>
export default {
  inheritAttrs: false,
  name: 'InputField',
  props: [
    'label',
    'placeholder',
    'cols',
    'row',
    'name',
    'rules',
    'valueField',
    'type',
    'disabled',
    'readonly',
    'exibirValidacao',
    'validacaoPopup',
    'validadores'
  ],

  data() {
    return {
      exibirCardValidacao: this.exibirValidacao,
      validaoEmFormatoPopup: this.validacaoPopup,
      validadoresSenha: this.validadores,
      tipoInput: this.type,
      text: this.valueField || '',
      iconSenha: 'mdi-eye-outline',
      tipoInput: 'password',
    }
  },

  methods: {
    verificarCondicao(senha) {
      for(const validador of this.validadoresSenha) {
        const regras = this.rules;

        if(regras && regras.length) {
          for(const regra of regras) {
            if(validador.erroId === regra(senha)) {
              validador.invalido = true;
              break;
            } else {
              validador.invalido = false;
            }
          }
        }
      }
    },

    trocarTipoInput() {
      if (this.tipoInput == 'password') {
        this.tipoInput = 'text'
        this.iconSenha = 'mdi-eye-off-outline'
      } else {
        this.tipoInput = 'password'
        this.iconSenha = 'mdi-eye-outline'
      }
    },
  },

  watch: {
    valueField: {
      immediate: true,
      handler(newText, oldText) {
        if ((newText === '' || newText) && newText !== oldText) {
          this.text = newText
        }
      }
    },
    text: {
      immediate: true,
      handler() {
        this.$emit('update:value', this.text)
      }
    },
    clearInput() {
      this.text = ''
    }
  }
}
</script>

<style scoped>
.coluna-input > .v-input {
  display: flex;
  flex-direction: column;
}
.label {
  font-weight: 600;
  color: rgb(98, 98, 98);
  margin-bottom: 5px;
}

.card-validacao-senha {
  color: #000 !important;
  width: 100%;
  background-color: #ffffff;
  border-radius: 0.3rem;
  margin-top: 0.2rem;
  padding: 0;
}

.card-validacao-senha .corpo-card {
  color: #000 !important;
}

.card-validacao-senha.popup {
  padding: 1rem;
  margin-top: 0;
  position: absolute;
  right: 0;
  top: 0;
  border: 1px solid #fdfdfd;
  box-shadow: 2px 1px 7px 0px rgb(12 12 12 / 21%);
  transform: translate(0, -102%);
}
.card-validacao-senha.popup .seta-popup {
  background: #ffffff;
  display: flex;
  position: absolute;
  right: 50%;
  bottom: -1px;
  width: 1.3rem;
  height: 1.3rem;
  transform: translate(0, 50%) rotateZ(45deg);
  border-bottom: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
}

.card-validacao-senha .itens {
  padding-left: 0;
  margin: 0;
}

.card-validacao-senha li {
  display: flex;
  align-items: center;
  list-style: none;
}

.card-validacao-senha li:not(:last-child) {
  margin-bottom: 0.3rem;
}
</style>
