
export const validadoresSenha = {
  data() {
    return {
      REGRAS_SENHA: [
        (value) => (!!value && (value.length >= 8 && value.length <= 12) )|| 'tamanhoSenha',
        (value) => (/[A-Z]/).test(value) || 'minimoLetraMaiuscula',
        (value) => (/[a-z]/).test(value) || 'minimoLetraMinuscula',
        (value) => (/[^0-9a-zA-Z\s]/).test(value) || 'minimoCaractereEspecial',
        (value) => (/[0-9]/).test(value) || 'minimoNumero',
        (value) => !(/(\w)\1{2,}|012|123|234|345|456|567|678|789|987|876|765|654|543|321|210/).test(value) || 'sequenciaIdentica',
      ],

      CONDICOES_SENHA: [
        {
          erroId: 'tamanhoSenha',
          condicao: 'Entre 8 e 12 caracteres',
          invalido: true
        },
        {
          erroId: 'minimoLetraMaiuscula',
          condicao: 'Pelo menos uma letra maiúscula',
          invalido: true
        },
        {
          erroId: 'minimoLetraMinuscula',
          condicao: 'Pelo menos uma letra minúscula',
          invalido: true
        },
        {
          erroId: 'minimoCaractereEspecial',
          condicao: 'Pelo menos 1 caractere especial',
          invalido: true
        },
        {
          erroId: 'minimoNumero',
          condicao: 'Pelo menos 1 número',
          invalido: true
        },
        {
          erroId: 'sequenciaIdentica',
          condicao: 'Não ter sequências ou indênticos como "123", "321" ou "aaa"',
          invalido: true
        }
      ]
    }
  }
};