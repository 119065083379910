var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.erroValidacao)?_c('v-btn',{staticClass:"radius",class:[
    { 'blue white--text': _vm.primary },
    { 'red darken-1 white--text': _vm.secondary },
    { 'yellow darken-3 white--text': _vm.warning },
    { 'green  white--text': _vm.success },
    { 'overflow-hidden': _vm.overflowHidden }
  ],style:(_vm.estilo),attrs:{"type":_vm.tipoBotao,"large":"","loading":_vm.loading,"disabled":_vm.desabilitar || _vm.loading},nativeOn:{"click":function($event){return _vm.$emit('clickBotao', _vm.evento)}}},[(_vm.icone)?_c('v-icon',{staticClass:"icone",class:[
      { 'animacacao-spin': _vm.animacao === 'spin' },
      { 'animacacao-download': _vm.animacao === 'download' }
    ],attrs:{"color":_vm.corIcone}},[_vm._v(" mdi-"+_vm._s(_vm.icone)+" ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.text)+" "),(_vm.iconeFinal)?_c('v-icon',{staticClass:"icone-final",class:[
      { 'animacacao-spin': _vm.animacaoIconeFinal === 'spin' },
      { 'animacacao-download': _vm.animacaoIconeFinal === 'download' }
    ]},[_vm._v(" mdi-"+_vm._s(_vm.iconeFinal)+" ")]):_vm._e()],1):_c('div',[_c('v-btn',{staticClass:"radius",class:[
      { 'blue white--text': _vm.primary },
      { 'red darken-1 white--text': _vm.secondary },
      { 'yellow darken-3 white--text': _vm.warning },
      { 'green  white--text': _vm.success },
      { 'overflow-hidden': _vm.overflowHidden }
    ],style:(_vm.estilo),attrs:{"type":_vm.tipoBotao,"large":"","loading":_vm.loading,"disabled":_vm.desabilitar || _vm.loading},nativeOn:{"click":function($event){return _vm.$emit('clickBotao', _vm.evento)}}},[(_vm.icone)?_c('v-icon',{staticClass:"icone",class:[
        { 'animacacao-spin': _vm.animacao === 'spin' },
        { 'animacacao-download': _vm.animacao === 'download' }
      ],attrs:{"color":_vm.corIcone}},[_vm._v(" mdi-"+_vm._s(_vm.icone)+" ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.text)+" "),(_vm.iconeFinal)?_c('v-icon',{staticClass:"icone-final",class:[
        { 'animacacao-spin': _vm.animacaoIconeFinal === 'spin' },
        { 'animacacao-download': _vm.animacaoIconeFinal === 'download' }
      ]},[_vm._v(" mdi-"+_vm._s(_vm.iconeFinal)+" ")]):_vm._e()],1),_c('div',{staticClass:"erro-validacao"},[_vm._v(_vm._s(_vm.erroValidacao))])],1)}
var staticRenderFns = []

export { render, staticRenderFns }