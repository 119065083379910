import axios from 'axios'
//TODO: RETIRAR GETTERS E SETTERS DE CPF E PERFIL APÓS ATUALIZAÇÃO DO COMPORTAMENTO DO ENDPOINT DE TOKEN
export default {
  namespaced: true,
  state: {
    token: null,
    user: null,
    controleacesso: null,
    perfil: null,
    cpf: null
  },
  getters: {
    authenticated(state) {
      return !!state.token
    },
    user(state) {
      return state.user
    },
    controleacesso(state) {
      return state.controleacesso
    },
    perfil(state) {
      return state.perfil
    },
    cpf(state) {
      return state.cpf
    }
  },
  mutations: {
    SET_TOKEN(state, token) {
      state.token = token
    },
    SET_PERFIL(state, perfil) {
      state.perfil = perfil
    },
    SET_CPF(state, cpf) {
      state.cpf = cpf
    },
    SET_USER(state, user) {
      if (user) {
        state.user = Object.assign(user, {
          avatar: `${process.env.VUE_APP_API_URL_DICEBEAR}?seed=${user.nome}`
        })
      }
    },
    SET_CONTROLEACESSO(state, controleacesso) {
      state.controleacesso = controleacesso
    }
  },
  actions: {
    async Action_Login({ dispatch, commit }, credentials) {
      const response = await axios.post('/api-agenda/login', credentials)
      dispatch('Action_AttempToken', response.data.access_token)
      commit('SET_USER', response.data)
      commit('SET_PERFIL', response.data.prefil)
      commit('SET_CPF', response.data.cpf)
      commit('SET_CONTROLEACESSO', response.data.modulos)
      return response
    },
    async Action_AttempToken({ commit, state }, token) {
      if (token) {
        commit('SET_TOKEN', token)
      }
      if (!state.token) {
        return
      }

      try {
        const { data } = await axios.post('/api-agenda/login/checkToken', token)
        commit('SET_USER', data)
        commit('SET_CONTROLEACESSO', data.modulos)
        commit('SET_PERFIL', data.prefil)
        commit('SET_CPF', data.cpf)
      } catch (e) {
        commit('SET_TOKEN', null)
        commit('SET_USER', null)
        commit('SET_CONTROLEACESSO', null)
      }
    },
    logOut({ commit }) {
      return new Promise((resolve) => {
        commit('SET_TOKEN', null)
        commit('SET_USER', null)
        commit('SET_PERFIL', null)
        commit('SET_CPF', null)
        commit('SET_CONTROLEACESSO', null)
        resolve(true)
      })
    }
  }
}
