<template>
  <v-overlay opacity="0.90"  :value="$store.state.loading">
    <v-progress-circular indeterminate size="64"></v-progress-circular>
  </v-overlay>
</template>

<script>
export default {
  data() {
    return {
      overlay: false,
    };
  },
  methods: {
    show(data) {
      this.overlay = data;
    },
  },
};
</script>

<style></style>
