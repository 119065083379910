import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store/index.js';
import Guard from '../services/middleware';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      title: 'CAAMA - Caixa de Assistência dos Advogados do Maranhão',
      modulos: ['HOME']
    },
    beforeEnter: Guard.home,
    component: () => import(/* webpackChunkName: "home" */ '../pages/Home.vue')
  },

  {
    path: '/institucional',
    name: 'InformacoesInstitucionais',
    meta: {
      title: 'Informações Institucionais',
      modulos: ['INSTITUCIONAL']
    },
    beforeEnter: Guard.auth,
    component: () =>
      import(
        /* webpackChunkName: "institucional" */ '../pages/institucional/AbasInstitucional.vue'
      )
  },
  {
    path: '/filiais',
    name: 'Filiais',
    meta: {
      title: 'Filiais',
      modulos: ['INSTITUCIONAL']
    },
    beforeEnter: Guard.auth,
    component: () =>
      import(
        /* webpackChunkName: "filiais" */ '../pages/institucional/Filiais.vue'
      )
  },
  {
    path: '/detalhes-filial/:id',
    name: 'DetalhesFilial',
    meta: {
      title: 'Detalhes da Filial',
      modulos: ['INSTITUCIONAL']
    },
    beforeEnter: Guard.auth,
    component: () =>
      import(
        /* webpackChunkName: "detalhes-filial" */ '../pages/institucional/AbasFilial.vue'
      )
  },

  {
    path: '/auxilio-maternidade',
    name: 'AuxilioMaternidade.vue',
    meta: {
      title: 'Auxílio Maternidade',
      modulos: ['AUXILIO']
    },
    beforeEnter: Guard.auth,
    component: () =>
      import(
        /* webpackChunkName: "auxilio-maternidade" */ '../pages/auxilio/maternidade/AuxilioMaternidade.vue'
      )
  },
  {
    path: '/detalhes-auxilio-maternidade/:id',
    name: 'DetalhesAuxilioMaternidade',
    meta: {
      title: 'Detalhes do Auxílio Maernidade',
      modulos: ['AUXILIO']
    },
    beforeEnter: Guard.auth,
    component: () =>
      import(
        /* webpackChunkName: "detalhes-auxilio-maternidade" */ '../pages/auxilio/maternidade/AbasAuxilioMaternidade.vue'
      )
  },
  {
    path: '/auxilio-funeral',
    name: 'AuxilioFuneral.vue',
    meta: {
      title: 'Auxílio Funeral',
      modulos: ['AUXILIO']
    },
    beforeEnter: Guard.auth,
    component: () =>
      import(
        /* webpackChunkName: "auxilio-funeral" */ '../pages/auxilio/funeral/AuxilioFuneral.vue'
      )
  },
  {
    path: '/detalhes-auxilio-funeral/:id',
    name: 'DetalhesAuxilioFuneral',
    meta: {
      title: 'Detalhes do Auxílio Funeral',
      modulos: ['AUXILIO']
    },
    beforeEnter: Guard.auth,
    component: () =>
      import(
        /* webpackChunkName: "detalhes-auxilio-funeral" */ '../pages/auxilio/funeral/AbasAuxilioFuneral.vue'
      )
  },

  {
    path: '/agenda',
    name: 'Agenda',
    meta: {
      title: 'Agendamento Médico',
      modulos: ['AGENDA', 'AGENDA ATENDENTE']
    },
    beforeEnter: Guard.auth,
    component: () =>
      import(/* webpackChunkName: "agenda" */ '../pages/agenda/Agenda.vue')
  },
  {
    path: '/lista-agenda',
    name: 'ListaAgenda',
    meta: {
      title: 'Listar Agenda Médica',
      modulos: ['AGENDA', 'AGENDA ATENDENTE']
    },
    beforeEnter: Guard.auth,
    component: () =>
      import(
        /* webpackChunkName: "agenda-lista" */ '../pages/agenda/AgendaListar.vue'
      )
  },
  {
    path: '/tabela-agenda',
    name: 'AgendaMonitoramento',
    meta: {
      title: 'Agendamentos',
      modulos: ['AGENDA', 'AGENDA ATENDENTE']
    },
    beforeEnter: Guard.auth,
    component: () =>
      import(
        /* webpackChunkName: "tabela-agenda" */ '../pages/agenda/AgendaMonitoramento.vue'
      )
  },

  {
    path: '/prontuario-medico',
    name: 'ProntuarioMedico',
    meta: {
      title: 'Prontuário Médico',
      //modulos: ['AGENDA COLABORADOR', 'AGENDA', 'AGENDA ATENDENTE']
      modulos: ['AGENDA COLABORADOR']
    },
    beforeEnter: Guard.auth,
    component: () =>
      import(
        /* webpackChunkName: "prontuario-medico" */ '../pages/prontuario/medico/ProntuarioMedico.vue'
      )
  },
  {
    path: '/detalhes-prontuario-medico/:slug',
    name: 'DetalhesProntuarioMedico',
    meta: {
      title: 'Detalhes do Prontuário Médico',
      //modulos: ['AGENDA COLABORADOR', 'AGENDA', 'AGENDA ATENDENTE']
      modulos: ['AGENDA COLABORADOR']
    },
    beforeEnter: Guard.auth,
    component: () =>
      import(
        /* webpackChunkName: "detalhes-prontuario-medico" */ '../pages/prontuario/medico/AbasProntuarioMedico.vue'
      )
  },

  {
    path: '/especialidades',
    name: 'ListaEspecialidades',
    meta: {
      title: 'Especialidades',
      modulos: ['COLABORADOR']
    },
    beforeEnter: Guard.auth,
    component: () =>
      import(
        /* webpackChunkName: "especializacoes" */ '../pages/colaborador/ListaEspecialidades.vue'
      )
  },
  {
    path: '/detalhes-especializacao/:id',
    name: 'DadosEspecialidade',
    meta: {
      title: 'Detalhes da Especialidade',
      modulos: ['COLABORADOR']
    },
    beforeEnter: Guard.auth,
    component: () =>
      import(
        /* webpackChunkName: "especializacoes" */ '../pages/colaborador/AbasEspecialidade.vue'
      )
  },

  {
    path: '/lista-colaboradores',
    name: 'ListaColaboradores',
    meta: {
      title: 'Colaboradores',
      modulos: ['COLABORADOR']
    },
    beforeEnter: Guard.auth,
    component: () =>
      import(
        /* webpackChunkName: "lista-colaboradores" */ '../pages/colaborador/Colaboradores.vue'
      )
  },
  {
    path: '/detalhes-colaborador/:id',
    name: 'DetalhesColaborador',
    meta: {
      title: 'Detalhes do Colaborador',
      modulos: ['COLABORADOR']
    },
    beforeEnter: Guard.auth,
    component: () =>
      import(
        /* webpackChunkName: "detalhes-colaborador" */ '../pages/colaborador/AbasColaborador.vue'
      )
  },
  {
    path: '/disponibilidade-colaborador',
    name: 'Disponibilidade',
    meta: {
      title: 'Disponibilidade',
      modulos: ['COLABORADOR']
    },
    beforeEnter: Guard.auth,
    component: () =>
      import(
        /* webpackChunkName: "disponibilidade-colaborador" */ '../pages/colaborador/Disponibilidade.vue'
      )
  },

  {
    path: '/login',
    name: 'Login',
    meta: {
      title: 'Login', hideNavigation: true
    },
    beforeEnter: Guard.isAuth,
    component: () => import(/* webpackChunkName: "login" */ '@/auth/Login.vue')
  },

  {
    path: '/usuarios',
    name: 'Usuarios',
    meta: {
      title: 'Usuários',
      modulos: ['ACESSO']
    },
    beforeEnter: Guard.auth,
    component: () =>
      import(/* webpackChunkName: "usuarios" */ '../pages/Usuarios.vue')
  },

  {
    path: '/perfil',
    name: 'Perfil',
    meta: {
      title: 'Perfil do Usuário',
      modulos: ['PERFIL COLABORADOR']
    },
    beforeEnter: Guard.auth,
    component: () =>
      import(
        /* webpackChunkName: "usuarios" */ '../pages/perfil-colaborador/AbasPerfil.vue'
      )
  },

  {
    path: '/lista-associados',
    name: 'Associado',
    meta: {
      title: 'Lista de Associados',
      modulos: ['ASSOCIADO']
    },
    beforeEnter: Guard.auth,
    component: () =>
      import(
        /* webpackChunkName: "lista-associados" */ '../pages/associado/Associado.vue'
      )
  },
  {
    path: '/detalhes-associado/:id',
    name: 'DetalheAssociado',
    meta: {
      title: 'Detalhes do Associado',
      modulos: ['ASSOCIADO']
    },
    beforeEnter: Guard.auth,
    component: () =>
      import(
        /* webpackChunkName: "detalhes-associado" */ '../pages/associado/AbasAssociado.vue'
      )
  },

  {
    path: '/gerenciar-portal',
    name: 'GerenciarPortal',
    meta: {
      title: 'Gerenciar Portal',
      modulos: ['PORTAL']
    },
    beforeEnter: Guard.auth,
    component: () =>
      import(
        /* webpackChunkName: "gerenciar-portal" */ '../pages/noticia/GerenciarPortal.vue'
      )
  },

  {
    path: '/redefinir-senha',
    name: 'RedefinirSenha',
    meta: {
      title: 'Redefinir Senha',
      modulos: ['USUARIO']
    },
    beforeEnter: Guard.auth,
    component: () =>
      import(
        /* webpackChunkName: "gerenciar-portal" */ '../pages/RedefinirSenha.vue'
      )
  },

  {
    path: '/lista-parceiros',
    name: 'Parceiro',
    meta: {
      title: 'Parceiros',
      modulos: ['PARCEIRO']
    },
    beforeEnter: Guard.auth,
    component: () =>
      import(
        /* webpackChunkName: "lista-parceiros" */ '../pages/parceiro/Parceiros.vue'
      )
  },
  {
    path: '/detalhes-parceiro/:id',
    name: 'DetalheParceiro',
    meta: {
      title: 'Detalhes do Parceiro',
      modulos: ['PARCEIRO']
    },
    beforeEnter: Guard.auth,
    component: () =>
      import(
        /* webpackChunkName: "detalhes-parceiro" */ '../pages/parceiro/AbasParceiro.vue'
      )
  },

  {
    path: '/integracao',
    name: 'Integracao',
    meta: {
      title: 'Integração',
      modulos: ['UTILITARIOS']
    },
    beforeEnter: Guard.auth,
    component: () =>
      import(
        /* webpackChunkName: "integracao" */ '../pages/utilitarios/pages/Integracao.vue'
      )
  }
];

const router = new VueRouter({
  routes,
  store,
  mode: 'history'
});

router.beforeEach((to, from, next) => {
  store.commit('setHeadeTitle', to.meta.title);
  next();
});

export default router;
