import axios from 'axios'

const orderColaborador = (a, b) => (a.nome > b.nome ? 1 : -1)

const setAvatarColaborador = (colaborador) => {
  return {
    ...colaborador,
    avatar: `${process.env.VUE_APP_API_URL_DICEBEAR}?seed=${colaborador.nome}`
  }
}

const atribuirParametros = (filtro) => {
  let parametros = [];
  for (const [key, value] of Object.entries(filtro)) {
    if(value || value === 0) parametros.push(`${key}=${value}`);
  }

  if(parametros.length <= 0) return '';
  return parametros.length === 1 ? `?${parametros}` : `?${parametros.join('&')}`;
}

export default {
  async getColaboradores() {
    const response = await axios.get(`/api-agenda/colaborador`)
    response.data = response.data
      .map(setAvatarColaborador)
      .sort(orderColaborador)
    return response
  },

  async getColaboradorByServico(servicoId) {
    const response = await axios.get(
      `/api-agenda/colaborador/servico/${servicoId}`
    )
    response.data = response.data
      .map(setAvatarColaborador)
      .sort(orderColaborador)
    return response
  },

  getServicos() {
    return axios.get(`/api-agenda/servico`)
  },

  getAgendaColaborador(colaboradorId) {
    return axios.get(
      `/api-agenda/agenda/backoffice/colaborador/${colaboradorId}`
    )
  },

  postAgendaColaborador(agendaColaborador) {
    return axios.post(`/api-agenda/agenda/backoffice`, agendaColaborador)
  },

  putAgendaColaborador(agendaColaborador) {
    return axios.put(
      `/api-agenda/agenda/backoffice/atualizar`,
      agendaColaborador
    )
  },

  getHorariosByData(data, servicoId, colaboradorId) {
    colaboradorId ? (colaboradorId = `colaborador=${colaboradorId}`) : ''
    return axios.get(
      `/api-agenda/agenda/backoffice/horario?data=${data}&servico=${servicoId}&${colaboradorId}`
    )
  },

  getHorariosByColaborador({ colaboradorId, data, servicoId }) {
    servicoId ? (servicoId = `servico=${servicoId}`) : ''
    return axios.get(
      `/api-agenda/agenda/backoffice/horario?colaborador=${colaboradorId}&data=${data}&${servicoId}`
    )
  },

  getDisponibilidadeColaborador(colaboradorId) {
    return axios.get(
      `/api-agenda/disponibilidadeColaborador/colaborador/${colaboradorId}`
    )
  },

  getDisponibilidadeColaboradorAndServico(colaboradorId) {
    return axios.get(
      `/api-agenda/disponibilidadeColaborador/servico/colaborador/${colaboradorId}`
    )
  },

  getTurnos() {
    return axios.get(`/api-agenda/turno`)
  },

  postDisponibilidadeColaborador(disponiblidade) {
    return axios.post(`/api-agenda/disponibilidadeColaborador`, disponiblidade)
  },

  getPerfis() {
    return axios.get(`/api-agenda/usuario/perfil`)
  },

  postUsuario(usuario) {
    return axios.post(`api-agenda/usuario`, usuario)
  },

  getArea() {
    return axios.get(`/api-agenda/area`)
  },

  postCadastroArea(area) {
    return axios.post(`/api-agenda/area`, area)
  },

  getTurnos() {
    return axios.get(`/api-agenda/turno`)
  },

  postTurnos(turno) {
    return axios.post(`/api-agenda/turno`, turno)
  },

  getParentesco() {
    return axios.get(`/api-associado/util/parentesco`)
  },

  postParentesco(parentesco) {
    return axios.post(`/api-associado/util/parentesco`, parentesco)
  },

  getOrgaoEmissor() {
    return axios.get(`/api-associado/util/orgaoExpedidor`, {
      useCredentails: true
    })
  },

  postOrgaoEmissor(orgao) {
    return axios.post(`/api-associado/util/orgaoExpedidor`, orgao)
  },

  getUsuarios(filtro) {
    const parametros = atribuirParametros(filtro);
    const url = `/api-agenda/usuario/filtro${parametros}`;
    return axios.get(url);
  },

  getUsuarioFiltro(filtro, pagination) {
    let { nome, cpf, usuario, perfil } = filtro
    nome ? (nome = `nome=${nome.toUpperCase()}&`) : (nome = '')
    cpf ? (cpf = `cpf=${cpf}&`) : (cpf = '')
    usuario ? (usuario = `usuario=${usuario}&`) : (usuario = '')
    perfil ? (perfil = `perfil=${perfil}&`) : (perfil = '')
    return axios.get(
      `/api-agenda/usuario/filtro?${cpf}${nome}${usuario}${perfil}page=0&size=${pagination.perPage}&sort=nome`
    )
  },

  getUsuarioById(usuarioId) {
    return axios.get(`/api-agenda/usuario/${usuarioId}`)
  },

  putUsuario(usuarioId, usuario) {
    return axios.put(`/api-agenda/usuario/${usuarioId}`, usuario)
  },

  getAssociado(pagination) {
    return axios.get(
      `/api-associado/associado/?page=${pagination.page}&size=${pagination.perPage}&sort=nome`
    )
  },

  putstatusAssociado(id, status) {
    return axios.put(`/api-associado/associado/status/${id}`, status)
  },

  deleteDisponibilidade(disponibilidadeId) {
    return axios.delete(
      `/api-agenda/disponibilidadeColaborador/${disponibilidadeId}`
    )
  },

  getAgendaFiltro(filtro) {
    const parametros = atribuirParametros(filtro);
    const url = `/api-agenda/agenda/backoffice/filtro${parametros}`;
    return axios.get(url);
  },

  getEspecialidadeByServico(servicoId) {
    return axios.get(`/api-agenda/especializacao/servico/${servicoId}`)
  },

  getAssociadoFiltro(pagination, filtro) {
    let { nome, cpf, numeroOab, status, atualizado } = filtro
    nome ? (nome = `nome=${nome.toUpperCase()}&`) : (nome = '')
    cpf ? (cpf = `cpf=${cpf}&`) : (cpf = '')
    numeroOab ? (numeroOab = `numeroOab=${numeroOab}&`) : (numeroOab = '')
    status ? (status = `status=${status}&`) : (status = '')
    atualizado
      ? atualizado === 2
        ? (atualizado = 'atualizado=true&')
        : (atualizado = 'atualizado=false&')
      : (atualizado = '')

    return axios.get(
      `/api-associado/associado/filtro?${nome}${cpf}${numeroOab}${status}${atualizado}page=${pagination.page}&size=${pagination.perPage}`
    )
  },

  getAgendaById(agendaId) {
    return axios.get(`/api-agenda/agenda/backoffice/horario/agenda/${agendaId}`)
  },

  deleteAgendaById(agendaId) {
    return axios.delete(`/api-agenda/agenda/backoffice/agenda/${agendaId}`)
  },

  deleteUserId(userId) {
    return axios.delete(`/api-agenda/usuario/${userId}`)
  },

  getDetalheAssociado(id) {
    return axios.get(`/api-associado/associado/${id}`)
  },

  putAtivarUsuario(usuarioId) {
    return axios.put(`/api-agenda/usuario/ativar/${usuarioId}`)
  },

  getEscritorioAssociado(id) {
    return axios.get(`/api-associado/escritorio/${id}`)
  },

  putDetalheAssociado(id, dados) {
    return axios.put(`/api-associado/associado/${id}`, dados)
  },

  putUsuarioSenha(usuarioId, senha) {
    return axios.put(`/api-agenda/usuario/alterarSenha/${usuarioId}`, senha)
  },

  getTipoNoticias() {
    return axios.get(`/api-agenda/tipoNoticia`)
  },

  getAllNoticias(pagination) {
    return axios.get(
      `/api-agenda/noticia/backoffice?page=${pagination.page}&size=${pagination.perPage}&sort=dataCadastro,desc`
    )
  },

  getAgendaMonitoramento(filtro) {
    const parametros = atribuirParametros(filtro);
    const url = `/api-agenda/agenda/backoffice/monitoramento${parametros}`;
    return axios.get(url)
  },

  getUf() {
    return axios.get(`/api-associado/util/uf`)
  },

  getCidadeFromUF(ufId) {
    return axios.get(`/api-associado/util/cidade/${ufId}`)
  },

  putResetarSenhaUsuario(id) {
    return axios.put(`/api-agenda/usuario/resetarSenha/${id}`)
  },

  postAgendaManual(agenda) {
    return axios.post(`/api-agenda/agenda/backoffice/cadastrar`, agenda)
  },

  putRecuperarSenha(data) {
    return axios.put('/api-agenda/usuario/recuperarSenha', data)
  },

  postHorarioAgendaManual(horario) {
    return axios.post(`/api-agenda/agenda/backoffice/horario`, horario)
  },

  putMudarSenha(id, senha) {
    return axios.put(`/api-agenda/usuario/alterarSenha/${id}`, senha)
  },

  getDisponibilidadeByData(data) {
    return axios.get(
      `/api-agenda/turno/colaborador/${data.id}?data=${data.data}`
    )
  },

  getStatusHorario(id) {
    return axios.get(`/api-agenda/status/${id}`)
  },

  putStatusHorario(horarioId, statusId, filtro) {
    const parametros = atribuirParametros(filtro);
    const url = `/api-agenda/agenda/backoffice/horario/${horarioId}/status/${statusId}${parametros}`;
    return axios.put(url);
  },

  putAgendarHorario(data) {
    return axios.put(`/api-agenda/agenda/portal`, data)
  },

  getNoticiasFiltro(filtro, pagination) {
    let { tipo } = filtro
    return axios.get(
      `/api-agenda/noticia/backoffice/tipo/${tipo}?page=${pagination.page}&size=${pagination.perPage}`
    )
  },

  deleteNoticia(id) {
    return axios.delete(`/api-agenda/noticia/backoffice/${id}`)
  },

  getNoticiaId(id) {
    return axios.get(`/api-agenda/noticia/${id}`)
  },

  postNoticiaId(data) {
    return axios.post(`/api-agenda/noticia/backoffice`, data)
  },

  puttNoticiaId(id, data) {
    return axios.put(`/api-agenda/noticia/backoffice/${id}`, data)
  },
}
