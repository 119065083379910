<template>
  <v-toolbar elevation="0" rounded height="90" class="mb-3">
    <v-app-bar-nav-icon @click="actionToggleHeader"></v-app-bar-nav-icon>
    <v-toolbar-title class="header">{{ getTitleHeader }}</v-toolbar-title>
    <v-spacer></v-spacer>
    <div v-if="user">
      <v-list-item class="grow">
        <v-list-item-content class="d-none d-sm-flex">
          <v-list-item-title>{{ user.nome }}</v-list-item-title>
        </v-list-item-content>

        <v-list-item-avatar>
          <v-img class="elevation-6" :src="user.avatar"></v-img>
        </v-list-item-avatar>
      </v-list-item>
    </div>
  </v-toolbar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  props: ['titulo'],
  data() {
    return {
      condition: null
    }
  },
  methods: {
    ...mapActions(['actionToggleHeader'])
  },
  computed: {
    ...mapGetters(['getTitleHeader']),
    user() {
      const usuario = this.$store.getters['auth/user']
      const nome = usuario.nome.split(' ');
      const regex = /^(de|da|do|dos|das|e|del)$/gi;

      if(nome.length === 1) {
        return usuario
      };

      if(nome.length === 2) {
        usuario.nome = nome[1].match(regex) ? `${nome[0]}` : `${nome[0]} ${nome[1]}`;
        return usuario
      };
      if(nome.length >= 3) {
        usuario.nome = nome[1].match(regex) ? `${nome[0]} ${nome[2]}` : `${nome[0]} ${nome[1]} ${nome[2]}`;
        return usuario
      };

      return '';
      }
  }
}
</script>

<style scoped>
/* .v-application--is-ltr
  .v-toolbar__content
  > .v-btn.v-btn--icon:first-child
  + .v-toolbar__title,
.v-application--is-ltr
  .v-toolbar__extension
  > .v-btn.v-btn--icon:first-child
  + .v-toolbar__title {
  padding-left: 5px;
} */

@media only screen and (max-width: 600px) {
  .header {
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 18px;
    text-transform: uppercase;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .header {
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 21px;
    text-transform: uppercase;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
</style>
